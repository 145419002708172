@import '../partials';

.post {

  .services-landing & {

    &-teaser {

      @include bp(md){
        flex-basis: 33.33%;
      }

      &__image {
        @include ratio-box(400,250);
      }
    }
  }

  &-teasers {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-top: rem(20);
    margin-left: rem(-10);
    margin-right: rem(-10);
    text-align: center;
  }

  &-teaser {
    display: block;
    margin-top: rem(20);
    max-width: rem(300);
    min-width: rem(300);
    padding: 0 rem(10);
    flex-basis: 50%;

    &:hover {
      .post-teaser__image-overlay {
        opacity: 1;
      }
    }

    @include bp(md){
      max-width: unset;
      flex-basis: 25%;
    }

    &__boundary {
      background-color: $white;
      box-shadow: $drop-shadow;
      height: 100%;
    }

    &__image {
      @include ratio-box(300,200);
      background-color: $gray;
      overflow: hidden;
      position: relative;
      font-size: 0; // fixes gap below images

      img {
        align-self: center;
        object-fit: cover;
        width: 100%;
      }

      &-overlay {
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.35);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem(20);
        transition: opacity $fast-duration ease;
      }
    }

    &__link-text {
      color: $white;
      font-size: rem(14);
      font-weight: 800;
      padding: rem(20) rem(40);
      border: 2px solid $white;
    }

    &__excerpt {
      padding: rem(20) 0 0 0;
      max-width: rem(280);
      margin: 0 auto;
      font-size: rem(14);
      line-height: rem(20);
    }

    &__link {
      padding-top: rem(30);
    }

    &__label {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      padding: rem(30) rem(20);
      min-height: rem(100);

      .services-landing & {
        padding: rem(75) rem(20);
      }
    }

    &__title {
      @include teaser-title;

      width: 100%;
    }

    &__subtitle {
      @include teaser-subtitle;

      width: 100%;
      margin-top: rem(8);
    }
  }
}